import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 6 - 9 months',
}

const questions: Array<Question> = [
  {
    question: 'How do tear trough fillers work?',
    answer:
      'Dermal fillers serve as a non-permanent replacement which will ' +
      'help restore volume to your skin, hydrating and rejuvenating your face. ' +
      'Because hyaluronic acid is found naturally in the skin, the filler when ' +
      'injected into the skin blends harmoniously with the skin.',
  },
  {
    question: 'How is the tear trough filler administered?',
    answer:
      'It is injected directly into the skin under the eyes using very ' +
      'fine needles that assures minimum discomfort. Anesthetic creams are ' +
      'provided if the need arises. After the injection, our aesthetic doctor ' +
      'will gently massage the treatment area to ensure even distribution.',
  },
]

const TearTroughFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'TEAR TROUGH FILLER - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="TEAR TROUGH FILLER" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                Do people ask you if you are feeling tired even though you had a
                good night’s sleep? Maybe concealer no longer disguises the dark
                shadows under your eyes?
              </p>
              <p>
                Over time, the top of our cheekbone drops and creates a hollow
                under the eye, known as the tear trough. This casts a shadow
                under the eyes which unfortunately gets darker with age.
              </p>
              <p>
                At Ada Aesthetics, we use premium dermal fillers containing
                hyaluronic acid to reduce the appearance of under-eye dark
                circles. You will look instantly look rested.
              </p>
            </div>
          </div>

          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default TearTroughFillers

export const pageQuery = graphql`
  query TearTroughFillers {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
